import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Footer from "../components/footer"

const Wrapper = styled.div`
  background-color: black;
  padding-top: 300px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 65px;
`

const H1 = styled.h1`
  margin-bottom: 2rem
`

const H2 = styled.h2`
  margin-bottom: 2rem
`

const P = styled.p`
  margin-bottom: 1rem
`

const DisclaimerPage = () => {
  return (
    <Layout>
      <SEO title="Disclaimer" />
      <Helmet htmlAttributes={{ class: "disclaimer" }} />
      <Wrapper>
        <div className="container">
          <H1>Disclaimer</H1>
          <div className="columns">
            <div className="column">
              <H2>
                Hamlet NV/SA
                <br />
                (Brussels)
              </H2>
              <P>
                Rue du Nom de Jésus 5<br />
                Naam Jezusstraat 5<br />
                BE-1000 Brussels
              </P>
              <P>
                T: +32 2 330 01 40
                <br />
                F: +32 2 330 01 44
                <br />
                E: brussels@hamlet.tv
              </P>
              <P>
                Court of Registration: RPR/RPM Brussels
                <br />
                BCE/KBO Registration Number: 0635.612.294
                <br />
                VAT/TVA/BTW: BE 0635.612.294
              </P>
            </div>
            <div className="column">
              <H2>Hamlet Berlin Films GmbH</H2>
              <P>
                Schönhauser Allee 163<br />
                DE-10435 Berlin
              </P>
              <P>
                E: berlin@hamlet.tv
              </P>
              <P>
                Geschäftsführer / Authorized managing directors: <br />
                Jason Felstead, Ruben Goots
                <br />
                Court of registration: Amtsgericht Charlottenburg
                <br />
                Registration Number: HRB 195834 B<br />
                Int. VAT ID No. pursuant to § 27a UmStG: DE317622904
              </P>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <H2>Accountability for content</H2>
              <P>
                We create the contents of our website with the utmost care.
                Nevertheless, we cannot assume liability for the actuality,
                correctness and completeness of the information provided.
              </P>
              <P>
                This website contains links to third party websites. We have no
                influence on these websites and the content displayed there and
                do not assume liability for them. When linking to such websites
                and external content for the first time, we paid attention to
                whether the linked contents are in violation to applicable laws.
                At that time, no violations were evident. Linking to external
                websites or content does not mean that we adopt them as our own.
                In the event of legal violations, the links to the relevant
                websites or content will be deleted immediately.
              </P>
              <P>
                With the use of freely accessible and free content no
                contractual relationship between the user and us is concluded.
              </P>
            </div>
            <div className="column">
              <H2>Haftung für Inhalte</H2>
              <P>
                Wir erstellen die Inhalte unserer Internetseite mit größter
                Sorgfalt. Dennoch können wir keine Haftung für die Aktualität,
                Richtigkeit und Vollständigkeit der bereit gestellten
                Informationen übernehmen.
              </P>
              <P>
                Diese Website enthält Verknüpfungen zu Websites Dritter. Auf
                diese Websites und die dort wiedergegebenen Inhalte haben wir
                keinen Einfluss und übernehmen dafür keine Haftung. Bei der
                erstmaligen Verlinkung auf solche Websites und fremde Inhalte
                haben wir darauf geachtet, ob etwaige Rechtsverstöße bestehen.
                Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Das
                Verlinken auf fremde Websites oder Inhalte bedeutet nicht, dass
                wir uns diese zu Eigen machen. Bei Kenntnis von Rechtsverstößen
                werden die Verlinkung auf die betreffenden Websites oder Inhalte
                unverzüglich löschen.
              </P>
              <P>
                Mit der Nutzung der frei zugänglichen und kostenlosen Inhalte
                kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und uns
                zustande.
              </P>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <H2>Copyright</H2>
              <P>
                Our web pages and their contents are subject to copyright law.
                Unless expressly permitted by law, every form of utilizing,
                reproducing or processing works subject to copyright protection
                on our web pages requires the prior consent of the respective
                owner of the rights. Individual reproductions of a work are
                allowed only for private use, so must not serve either directly
                or indirectly for earnings. Unauthorized utilization of
                copyrighted works is illegal.
              </P>
            </div>
            <div className="column">
              <H2>Urheberrecht</H2>
              <P>
                Unsere Website und deren Inhalte unterliegen dem Urheberrecht.
                Sofern nicht ausdrücklich gesetzlich zulässig, bedarf jede Form
                der Verwertung, Reproduktion oder Verarbeitung urheberrechtlich
                geschützter Werke auf unseren Websites der vorherigen Zustimmung
                des jeweiligen Rechteinhabers. Reproduktionen eines Werkes sind
                nur für den privaten Gebrauch gestattet, dürfen also weder
                direkt noch indirekt wirtschaftlichen Zwecken dienen. Die
                unerlaubte Verwendung urheberrechtlich geschützter Werke ist
                illegal.
              </P>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <H2>Privacy Policy</H2>
              <P>
                When visiting our website, we do not collect your personal data.
                We will not track your surfing behavior at any time. The
                information we collect is limited to technical information not
                associated with a particular person, e.g. IP address and home
                URL. These are used by us for technical purposes, e.g. for
                language selection.{" "}
              </P>
              <P>
                On our website, we use session cookies that are deleted after
                the web browser window is closed, but no later than 48 hours
                after visiting our website. Your login data in the client area
                will be saved for 14 days.{" "}
              </P>
              <P>
                Cookies are informational data, more specifically small text
                files that are stored and collected on the devices. Cookies are
                used to maintain the session, the language settings, and the
                security and reliability of the service.{" "}
              </P>
              <P>
                Accepting cookies is not a prerequisite for visiting our
                website. If you do not accept the storage of cookies, the
                functionality may be restricted when you use the website.
              </P>
              <P>
                By using our website, you consent to the use of cookies (as
                described above), thus collecting, storing and using usage data.
                You also agree that your data will be stored and accessed in
                cookies beyond the end of the browser session. You can revoke
                this consent at any time with effect for the future. To do so,
                you can refuse settings for your web browser to accept cookies
                and delete stored cookies.
              </P>
              <P>
                At any time, you can independently block the acceptance of
                cookie files by customizing your web browser settings. These
                settings may be modified, in particular, to block cookies, i.e.
                can not be accepted and saved. Detailed information on the
                possibilities and ways of dealing with cookies are available in
                the software settings (web browser). You can also delete the
                cookies at any time in the settings of your browser program.
              </P>
            </div>
            <div className="column">
              <H2>Datenschutz</H2>
              <P>
                Bei dem Besuch unserer Website erheben wir keinerlei
                personenbezogene Daten. Wir werden Ihr Surfverhalten zu keiner
                Zeit aufzeichnen oder nachverfolgen.
              </P>
              <P>
                Die Informationen, die wir erheben, beschränken sich auf
                technische Informationen, die nicht einer bestimmten Person
                zugeordnet sind, z.B. IP-Adresse und Herkunfts-URL. Diese werden
                von uns für technische Zwecke, etwa für die Sprachwahl,
                verwendet.
              </P>
              <P>
                Auf unserer Website verwenden wir Session Cookies, die gelöscht
                werden, nachdem das Web-Browser-Fenster geschlossen wird,
                spätestens aber 48 Stunden nach dem Besuch unserer Website. Ihre
                Zugangsdaten beim Login im Mandantenbereich werden für 14 Tage
                gespeichert.
              </P>
              <P>
                Cookies sind Informationsdaten, vor allem kleine Textdateien,
                die auf den Geräten gespeichert und gesammelt werden. Cookies
                werden für die Aufrechterhaltung der Sitzung,
                Spracheinstellungen und die Gewährleistung der Sicherheit und
                Zuverlässigkeit des Dienstes verwendet.
              </P>
              <P>
                Das Annehmen von Cookies ist keine Voraussetzung zum Besuch
                unserer Website. Akzeptieren Sie die Speicherung von Cookies
                jedoch nicht, kann die Funktionalität bei der Benutzung der
                Website eingeschränkt sein.
              </P>
              <P>
                Mit Nutzung unserer Website willigen Sie ein, dass Cookies (wie
                vorstehend beschrieben) eingesetzt und damit Nutzungsdaten
                erhoben, gespeichert und genutzt werden. Sie erklären ferner
                Ihre Einwilligung, dass Ihre Daten in Cookies über das Ende der
                Browser-Sitzung hinaus gespeichert werden und wieder aufgerufen
                werden können. Diese Einwilligung können Sie jederzeit mit
                Wirkung für die Zukunft widerrufen. Hierzu können Sie
                Einstellungen Ihres Webbrowsers die Annahme von Cookies
                verweigern und gespeicherte Cookies löschen.
              </P>
              <P>
                Sie können jederzeit selbstständig die Annahme von
                Cookie-Dateien unterbinden, indem Sie Ihre
                Web-Browser-Einstellungen entsprechend anpassen. Diese
                Einstellungen können insbesondere dahingehend geändert werden,
                dass Cookies blockiert, d.h. nicht angenommen und gespeichert
                werden. Detaillierte Informationen über die Möglichkeiten und
                Wege im Umgang mit Cookies sind in den Softwareeinstellungen
                (Web-Browser) verfügbar. Sie können die Cookies darüber hinaus
                jederzeit in den Einstellungen Ihres Browser-Programms löschen.
              </P>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default DisclaimerPage
