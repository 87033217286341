import React, { useRef, useEffect } from "react"

const words = [
  "curious",
  "creative",
  "adventurous",
  "ambitious",
  "artistic",
  "bossy",
  "charming",
  "tidy",
  "messy",
  "cynical",
  "enthusiastic",
  "emotional",
  "decisive",
  "friendly",
  "funny",
  "generous",
  "gentle",
  "impatient",
  "impulsive",
  "goofy",
  "honest",
  "lazy",
  "optimistic",
  "persistent",
  "pessimistic",
  "rational",
  "sarcastic",
  "self-centered",
  "sensible",
  "serious",
  "shy",
  "sincere",
  "stubborn",
  "thoughtful",
  "tactless",
]

const Tagline = props => {
  const tagline = useRef(null)
  useEffect(() => {
    const wordHandler = setInterval(_ => {
      tagline.current.innerHTML = words[Math.floor(Math.random() * words.length)]
    }, 1500)
    return () => {
      clearTimeout(wordHandler)
    }
  })
  return (
    <h3>
      For the <span ref={tagline}>curious</span>
    </h3>
  )
}

export default Tagline
