import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Tagline from "../components/tagline"
import Scroll from "../components/scroll"
import "./footer.sass"

const Menu = styled.div`
  font-size: 0.9rem;

  h6 {
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
    margin: 0;
  }

  a {
    color: #121212;
    text-decoration: none;

    &:hover {
      color: #121212;
      text-decoration: underline;
    }
  }
`

const Footer = ({regionSlug}) => (
  <div>
    <div className="footer-wrapper">
      <Tagline />
      <Menu>
        <h6>Site</h6>
        <ul>
          <li>
            <Link to={`/${regionSlug}/directors`}>Directors</Link>
          </li>
          <li>
            <Link to={`/${regionSlug}/about`}>About + Contact</Link>
          </li>
          <li>
            <Link to="/disclaimer">Disclaimer</Link>
          </li>
        </ul>
      </Menu>
      <Menu>
        <h6>Links</h6>
        <ul>
          <li>
            <a href="https://www.instagram.com/hamlet.tv/" alt="Instagram">
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/hamletforthecurious/"
              alt="Facebook"
            >
              Facebook
            </a>
          </li>
          <li></li>
          <li></li>
        </ul>
      </Menu>
    </div>
    <footer className="footer">
      <div>© {new Date().getFullYear()} All Rights Reserved.</div>
      <Scroll scrollStepInPx="50" delayInMs="16.6" />
    </footer>
  </div>
)

export default Footer
