import React from "react"
import styled from "styled-components"

const BackToTop = styled.a`
  color: #121212;

  &:hover {
    color: #121212;
    text-decoration: underline;
  }
`

class Scroll extends React.Component {

  constructor(props) {
    super(props)
    this.handleScroll.bind(this)
    this.state = {
      intervalId: 0,
      pageYOffset: 0,
    }
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId)
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx)
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    )
    this.setState({ intervalId: intervalId })
  }

  handleScroll() {
    let pageYOffset = window.pageYOffset
    if (pageYOffset > 100) {
      document.getElementById('backtotop').classList.add('show')
    } else {
      document.getElementById('backtotop').classList.remove('show')
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    return <BackToTop id="backtotop" onClick={() => this.scrollToTop()}>Back to top</BackToTop>
  }
}

export default Scroll
